import type { Game, GamesList } from "~/types/games";

export const useGamesStore = defineStore("gamesStore", () => {
  /**
   * Данные по играм.
   */
  const games = ref<Game[]>([]);
  const count = ref(0);
  /**
   * Общее кол-во игр.
   */
  const total = ref(0);

  /**
   * Индикатор загрузки следующей части игр.
   */
  const loading = ref(false);
  /**
   * Кол-во загружаемых игр в одном запросе.
   */
  const limit = 16;
  const baseURL = isClient()
    ? `${useRuntimeConfig().public.clientApiBase}api/`
    : `${useRuntimeConfig().public.serverApiBase}api/`;

  async function fetchGames(page: number) {
    const { data } = await useFetch<GamesList>(
      `/games/?limit=${limit}&offset=${page * limit}`,
      {
        baseURL,
        credentials: "include",
      },
    );
    if (
      data.value &&
      "count" in data.value &&
      "next" in data.value &&
      "results" in data.value &&
      Array.isArray(data.value.results)
    ) {
      games.value = data.value.results;
      count.value = data.value.count;
      total.value = Math.ceil(data.value.count / limit);
    }
  }

  return {
    games,
    count,
    total,
    loading,
    fetchGames,
  };
});
